<script>
/**
 * Index
 * @version 1.0.0
 * @since
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzWizard from '@/components/layout/Wizard.vue';
import EzButton from '@/components/ui/Button';
import { mapMutations } from 'vuex';
import steps, {
  VENUE_STEP_1,
  VENUE_STEP_2,
  VENUE_STEP_3,
} from './steps';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  props: {
    prefilledName: {
      type: String,
    },
  },
  provide() {
    return {
      venueId: this.venueId,
    };
  },
  data() {
    return {
      currentStep: 0,
      VENUE_STEP_1,
      VENUE_STEP_2,
      VENUE_STEP_3,
      nextDisabled: true,
    };
  },
  computed: {
    venueId() { return this.$route.query.state; },
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === VENUE_STEP_1;
    },
    isLastStep() {
      return this.currentStep === VENUE_STEP_3;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('xero', ['CLEAR_STATE']),
    onWizardExit() {
      this.CLEAR_STATE();
      this.$router.push({ name: 'venue-settings-integrations' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= VENUE_STEP_1 && step <= VENUE_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= VENUE_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= VENUE_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  created() {
    if (!this.venueId) {
      this.$router.push({
        name: 'venue-xero-not-connected',
        params: {
          flash: {
            message: 'Something went wrong!',
            type: 'error',
          },
        },
      });
    }
  },
};
</script>
<template>
  <ez-wizard
    :fullWidth="currentStep === VENUE_STEP_2"
    @exit="onWizardExit"
    @back="goToPreviousStep">
    <template #title>{{ $t('xero.wizzard.title') }}</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === VENUE_STEP_1,
          'ez-step-completed': currentStep > VENUE_STEP_1,
        }">
          {{ $t('xero.wizzard.venue.step1.title') }}
        </li>
        <li :class="{
          'ez-step-active': currentStep === VENUE_STEP_2,
          'ez-step-completed': currentStep > VENUE_STEP_2,
        }">
          {{ $t('xero.wizzard.venue.step2.title') }}
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep">{{ nextCta }}</ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep > VENUE_STEP_1 && currentStep < VENUE_STEP_3"
        type="link"
        formType="button"
        @click="goToPreviousStep">
        <font-awesome-icon icon="arrow-left"/>
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>{{ currentStepPageInfo }}</template>
    <component
      :prefilledName="prefilledName"
      :is="currentStepComponent"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"></component>
  </ez-wizard>
</template>
<style lang="scss" scoped></style>
